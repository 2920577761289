
<template>
  <v-container>
    <h3 style="padding-bottom: 20px;">设置管理</h3>
    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->
    
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            SMTP MX（收件） 服务器状态
          </v-card-title>
          <v-card-text>
            <div v-if="is_running()" style="color:green;font-weight: bolder;">运行中 <v-btn x-small @click="smtpStart()">重新启动</v-btn></div>
            <div v-else style="color:red;font-weight: bolder;">未运行 <v-btn x-small @click="smtpStart()">重新启动</v-btn></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            用户管理
          </v-card-title>
          <v-card-text>
            <v-checkbox label="允许新用户注册" v-model="set_allow_register" @change="updateSet()"></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-btn x-small @click="genAPK()">生成全局 API-KEY</v-btn>
          </v-card-title>
          <v-card-text>
            
            <div style="padding: 4px;"><div>appId: <strong>{{appId}}</strong> </div>
            <div>appSecret: <strong>{{appSecret}}</strong></div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            请填写私钥和证书信息 并保存&nbsp;&nbsp;<v-btn x-small @click="savePERM()">保存TLS key</v-btn>
          </v-card-title>
          <v-card-title>
            如果你不会填写，可以尝试 &nbsp;&nbsp; <v-btn x-small @click="genPERM()">自动生成全局 TLS key</v-btn>
          </v-card-title>
          <v-card-text>

          <v-textarea
          outlined
          id="idSetKEY"
          label="私钥文件"
          v-model="setKey"
          hint="私钥文件"
          style="font-family:monospace;"
        ></v-textarea>
            
        <v-textarea
          outlined
          id="idSetCert"
          label="证书信息"
          v-model="setCert"
          hint="证书信息"
          style="font-family:monospace;"
        ></v-textarea>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>



  </v-container>
</template>
 
<script>
import axios from 'axios';


export default {

  name: "vTyWorkSpace",

  data: () => ({
    logininfo : {},
    allow_register : false,
    set_smtp_info : {},
    set_extra : {},
    appId:"",
    appSecret:"",
    setKey:"",
    setCert:"",
    setSaveMsg: ""
  }),
  methods:{

    loadSetting:function(){
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      axios.get('/api/setFetch' ).then( res=>{
         console.log( "fecthSET", res );
         if ( res.status == 200 && res.data.code==200){
              this.set_extra = res.data.data ;
              this.appId = this.set_extra['appId'];
              this.appSecret = this.set_extra['appSecret'];
              this.setCert = this.set_extra['setCert'];
              this.setKey = this.set_extra['setKey'];
              console.log("sssss 1 =>", this.set_extra); 
          }
      }).catch( err=>{ 
        console.log( "fecthSET ERR", err );
       }
      );
      
    },
    is_running:function(){
      console.log("Info", this.set_smtp_info);
      if ( this.set_smtp_info.start_ctime > 0)
          return true;
      return false
    } ,

    updateSet:function(){
        console.log( "update set", this.set_extra);
        let customConfig = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        let jsData = JSON.stringify( this.set_extra );
        axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
        axios.post( '/api/setUpdate', jsData, customConfig ).then( res=>{ 
          console.log("res==>", res);
          if ( res.status == 200 && res.data.code==200){
              this.set_extra = res.data.data ;
              console.log("sssss", this.set_extra); 
          }
        }).catch(
          err=>{
            console.log("err==>", err); 
          }
        ); 
        
    } ,
    smtpStart:function(){
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      axios.get('/api/restartCSmtp' ).then( res=>{
        console.log(res);
        if ( res.status == 200 && res.data.code == 200){
          this.set_smtp_info = res.data.data;
        }else{
          let dummy = 1;
          dummy;
        }
      }).catch(err=>{
          console.log("play task", err);
      });
    },
    genAPK:function(){
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      axios.get('/api/getGlobalAppIDnSecret' ).then( res=>{
        console.log(res);
        if ( res.status == 200 && res.data.code == 200){
          this.appId = res.data.data.appId;
          this.appSecret = res.data.data.appSecret;
        }else{
          let dummy = 1;
          dummy;
        }
      }).catch(err=>{
          console.log("play task", err);
      });
    },
    genPERM:function(){
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      axios.get('/api/getGlobalPERM' ).then( res=>{
        console.log("genPERM", res);
        if ( res.status == 200 && res.data.code == 200){
          this.setKey = res.data.data.setKey;
          this.setCert = res.data.data.setCert;
          this.setSaveMsg = "生成成功";
        }else{
          this.setSaveMsg = res.data.message;
          let dummy = 1;
          dummy;
        }
      }).catch(err=>{
          console.log("play genPERM execept ", err);
      });
    },
    savePERM:function(){
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      let jsData = { "cert": this.setCert, "key": this.setKey};
      axios.post('/api/saveGlobalPERM',  jsData ).then( res=>{
        console.log("save Cert ", res );
        if ( res.status == 200 && res.data.code == 200){
          this.setKey = res.data.data.setKey;
          this.setCert = res.data.data.setCert;
          this.setSaveMsg = "保存成功";
        }else{
          this.setSaveMsg = res.data.message;
          let dummy = 1;
          dummy;
        }
      }).catch(err=>{
          console.log("play savePERM execept ", err);
      });
    }
    
  },
  mounted: function(){
    let alt = 2;
    if (alt==1) return;

    this.loadSetting();
    
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    axios.get('/api/getCSmtp' ).then( res=>{
      console.log(res);
      if ( res.status == 200 && res.data.code == 200){
        this.set_smtp_info = res.data.data;
      }else{
        let dummy = 1;
        dummy;
      }
    })
    .catch( err=>{
      console.log(err);
    });
  },
  computed:{
    set_allow_register: {
      get(){ return this.set_extra['allow_register'] == true },
      set(value) { this.set_extra['allow_register'] = value; 
        return value;}
    }
  },
  async created(){
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    await axios.get('/api/profile' ).then(
      response=>{
        console.log("profile ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            this.logininfo = response.data.data;
        }else{
          window.location = '/login';
        }
      }
    )
    .catch(err=>{
      window.location = '/login';
      console.log(err);
    });
  }
};

</script>

