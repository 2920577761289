
<template>
    <h1>Hello {{ $route.params.msg}}</h1>
  <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->
</template>
 
<script>
    export default {
        name: "vHelloWorld",
 
        data: () => ({
 
        }),
    }
/* 以上就是一个最简单的vue组件，它由template和script两部分组成，template部分表示HTML代码，
很简单，就是显示一个H1级别的文字：Hello + 路由传过来的参数，
JS部分其实并没有任何实质性内容，是一个空框架。 */
</script>

