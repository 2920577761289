import VueRouter from "vue-router";
import vIndexHello from './components/vIndexHello'
import vTyWorkSpace from './components/vTyWorkSpace'
import vEmailList from './components/vEmailList'
import vSendLogs from './components/vSendLogs'
import vSetting from './components/vSetting'
import vDomainAcc from './components/vDomainAcc'
import vEmailContent from './components/vEmailContent' 
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
} //以上三行是为了解决相同路由报错的问题，不用深究
 
export default new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', name: 'vHome', component: vTyWorkSpace},
        {path: '/hello/:msg', name: 'vPageHello', component: vIndexHello},
        {path: '/dash/:msg', name: 'vDomains', component: vTyWorkSpace},
        {path: '/email/:msg', name: 'vEmails', component: vEmailList},
        {path: '/sendlogs/:msg', name: 'vSendLogs', component: vSendLogs},
        {path: '/setting/:msg', name: 'vSetting', component: vSetting},
        {path: '/mailcontent/:mailid', name: 'vEmailContent', component: vEmailContent},
        {path: '/editdomain/:msg', name: 'vPageEditDomain', component: vDomainAcc},
        
    ]
}) 
