
<template>
  <v-container>
    <v-row><v-col cols="12" sm="6"><h1 style="padding-bottom: 20px;">域名设置 {{ $route.params.msg }} </h1></v-col> <v-col cols="12" sm="6"><a style="vertical-align: bottom" href="/dash/domains"> 返回域名列表 </a> </v-col></v-row>
    <v-row>
      <v-col cols="12" sm="6">
        域名
        <v-text-field v-model="domain" @keyup="makeDns" @blur="makeDns" placeholder="输入域名"></v-text-field
      ></v-col>
      <v-col cols="12" sm="6">
        主收信IP
        <v-text-field v-model="ip" @keyup="makeDns" @blur="makeDns" @click="loadServerIp($event)" placeholder="点击自动获取本机IP"></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <v-col><h2>收信mx设置</h2></v-col>
    </v-row>
    <v-row>
      <v-col
        ><div style="padding-bottom: 4px;">根据下面的指引，设置dns mx记录</div>
        <v-card>
          <v-card-title> 域名: <strong>{{domain}}</strong> </v-card-title>
          <v-card-text> <div>DNS记录类型: <strong> MX </strong> </div>
            <div>DNS记录内容: <strong>{{ dns_mx }}</strong></div>
            <div>优先级: <strong>{{ 10 }}</strong></div>
          </v-card-text>
          
          <v-card-title> 域名: &nbsp;<strong>{{ dns_a}}</strong> </v-card-title>
          <v-card-text> <div>DNS记录类型: <strong> A </strong> </div>
            <div>DNS记录内容: &nbsp; <strong>{{ ip }}</strong></div></v-card-text>
          <v-card-text> </v-card-text>
        </v-card></v-col
      >
    </v-row>
    <v-row>
        <v-col cols="12" sm="3">
            <v-btn size="30" :loading="mbStatus.isLoading" :disabled="mbStatus.isDisabled" block @click="checkDNS($event,'mx')" color="primary">验证DNS MX记录</v-btn>
        </v-col >
        <v-col cols="12" sm="9"><v-alert text dense :type="dns_mx_alert.type" v-show="dns_mx_alert.show"> {{dns_mx_alert.message}}</v-alert></v-col>
    </v-row>
    
    <v-row>
      <v-col><h2>发信设置</h2></v-col>
    </v-row>
    <v-row>
      <v-col><h3>1. SPF设置</h3></v-col>
    </v-row>
    <v-row>
      <v-col
        ><div style="padding-bottom: 4px;">根据下面的例子，设置dns SPF记录</div>
        <v-card>
          <v-card-title> 域名: <strong>{{domain}}</strong> </v-card-title>
          <v-card-text> <div>DNS记录类型: <strong> TXT </strong> </div>
            <div>DNS记录内容: <strong> v=spf1 a mx ip4:{{ ip }} ip4:{{ ip }} -all </strong></div>
            (ip4发信ip，上面仅仅是举例，需要根据你的发信出口ip修改)
          </v-card-text>
          
        </v-card></v-col
      >

    </v-row>
    <v-row>
      <v-col><h3>2. DMARC设置</h3></v-col>
    </v-row>
    <v-row>
      <v-col><h4>填写default.txt文件</h4></v-col>
    </v-row>
    <v-row>
      <v-col><div> 运行 <div style="background-color:#FFFFE0;padding: 8px;">opendkim-genkey -d {{domain}} -s {{dmarc_selector}}  </div> </div>
      <div>当前目录，找到default.txt ，将文件内容粘贴到下面。或者你可点击下面按钮自动获取DKIM信息</div> </v-col>
    </v-row>
    <v-row>
      <v-col> <v-btn x-small @click="getDKIM()">自动获取DKIM</v-btn> </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          outlined
          id="idmarc_txt"
          label="default.txt文件内容"
          v-model="dmarc_txt"
          hint="dkim key"
          style="font-family:monospace;"
          @change="syncDMarc"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col><h4>填写default.private文件</h4></v-col>
    </v-row>
    <v-row>
      <v-col>
      <div>运行上面命令后，在当前目录找到default.private ，将文件内容粘贴到下面</div> </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          outlined
          id="idmarc_private"
          label="default.private文件内容"
          v-model="dmarc_key"
          hint="dkim private key"
          style="font-family:monospace;"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        ><div style="padding-bottom: 4px;">根据下面的提示，设置dns DMarc记录</div>
        <v-card>
          <v-card-title> 域名: <strong>{{dmarc_selector}}._domainkey.{{domain}}</strong> </v-card-title>
          <v-card-text> <div>DNS记录类型: <strong> TXT </strong> </div>
            <div>DNS记录内容: 
              </div>
            <div>{{dns_dmarc}}</div> 
              
          </v-card-text>
          
        </v-card></v-col
      >
      
    </v-row>
    <v-row>
        <v-col cols="12" sm="3">
            <v-btn size="30" :loading="dmarc_Status.isLoading" :disabled="dmarc_Status.isDisabled" block @click="checkDNSDmarc($event,'dmarc')" color="primary">验证DNS DMARC记录</v-btn>
        </v-col >
        <v-col cols="12" sm="9"><v-alert text dense :type="dmarc_alert.type" v-show="dmarc_alert.show"> {{dmarc_alert.message}}</v-alert></v-col>
    </v-row>

    <v-row>
      <v-col><h3>3. 发信节点设置</h3></v-col>
    </v-row>
    <v-row>
      <v-col><v-text-field
            v-model="extrForm_rule_limit"
            label="发信限制"
            clearable
          ></v-text-field></v-col>
    </v-row>
    <v-row>
      <v-col><v-text-field
            v-model="extrForm_rule_pwd"
            label="发信密码"
            clearable
          ></v-text-field></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn  block @click="genAppidSecret()"  >生成appid/appsecret</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col><v-text-field
            v-model="extrForm_appid"
            label="appid"
            clearable
          ></v-text-field></v-col>
    </v-row>
    <v-row>
      <v-col><v-text-field
            v-model="extrForm_appsecret"
            label="appsecret"
            clearable
          ></v-text-field></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn size="20"  block @click="saveExtra()" color="primary">保存发信节点设置</v-btn>
      </v-col>
      <v-col cols="12" sm="9"><v-alert text dense :type="extra_alert.type" v-show="extra_alert.show"> {{extra_alert.message}}</v-alert></v-col>
    </v-row>

    <v-row>
      <v-col><h3>4. 发信节点配置文件</h3></v-col>
    </v-row>
    <v-row>
      <v-col
        ><div style="padding-bottom: 4px;">复制 <strong> {{ this.domain }} </strong>单域名配置文件到节点，再重启节点docker</div>
        <v-card>
          
          <v-textarea
          outlined
          id="idenvcfg"
          label="配置文件.conf"
          v-model="envConf"
          hint="配置文件"
          style="font-family:monospace;"
        ></v-textarea>
          
        </v-card></v-col
      >
      
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn size="20"  block @click="genConfigText()" color="primary">生成发信节点配置文件</v-btn>
      </v-col>
      <v-col cols="12" sm="9"><v-alert text dense :type="configText_alert.type" v-show="configText_alert.show"> {{configText_alert.message}}</v-alert></v-col>
    </v-row>
    
  </v-container>
</template>
<script>
  if ( typeof ( String.prototype.replaceAll3 ) == 'undefined'){
    console.log("add New replaceAll3")
    String.prototype.replaceAll3 = function (s1,s2){     
        return this.replace(new RegExp(s1,"gm"),s2);     
    } 

  }
</script>
<script>

import axios from 'axios';

export default {
  name: "vDomainAcc",

  data: () => ({
    loading_fail : true,
    logininfo:{},
    domain: "",
    dns_mx: "",
    dns_a: "",
    ip:"",
    dns_dmarc:"",
    dmarc_key:"", 
    dmarc_txt:"",
    js_status_loading: false,
    mbStatus:{ isLoading:false , isDisabled:false },

    dns_mx_alert:{ type:"info", show:false, message:""},

    dmarc_Status:{ isLoading:false , isDisabled:false },
    dmarc_alert:{ type:"info", show:false, message:""},
    dmarc_selector:"default",
    xrefid : (Math.random() + new Date().getTime()).toString(32).slice(0,8),
    extrForm_rule_limit : "",
    extrForm_rule_pwd : "",
    extrForm_appid : "",
    extrForm_appsecret : "",
    extra_alert:{ type:"info", show:false, message:""},
    envConf: "",
    configText_alert:{ type:"info", show:false, message:""},
  }),

  methods: {
    
    resetStatus:function()
    {
        this.js_status_loading = false;
        this.dns_mx_alert = { type:"info", show:false, message:""};
    },
    makeDns: function()
    {
        console.log("domainis ", this.domain);
        this.dns_mx = "mx1." + this.domain + ".";
        this.dns_a = "mx1."  + this.domain ;
        console.log("BASE URL " + this.cfgset );
        this.resetStatus();

    },
    checkDNS:function(event, dns_type)
    {
        this.mbStatus.isLoading = true;
        this.mbStatus.isDisabled = true;
        console.log("event.target", event.target);
        console.log("check DNS for " , this.domain, this.ip );
        axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
        axios.post('/api/checkDNS', { domain:this.domain , type:dns_type, extra: this.dns_a,  content: this.ip, xrefid: this.xrefid } ).then( response=>{
            console.log( "checkDNS OK, resp ", response);
            if (response.status == 200 ){
                if( response.data.code == 200 && response.data.message == 'OK'){
                   this.dns_mx_alert.message = "设置成功";
                   this.dns_mx_alert.type = 'success';
                }else{
                    this.dns_mx_alert.message = "设置失败，请检查dns的mx记录";
                    this.dns_mx_alert.type = "warning";
                }
                this.dns_mx_alert.show = true;
                this.mbStatus.isLoading = false;
                this.mbStatus.isDisabled = false;
            }
        }

        ).catch( (error)=>{
            console.log( "checkDNS ERROR", error );
            this.mbStatus.isLoading = false;
            this.mbStatus.isDisabled = false;
        });
        
    },
    checkDNSDmarc:function(event, dns_type)
    {
        this.dmarc_Status.isLoading = true;
        this.dmarc_Status.isDisabled = true;
        console.log("event.target", event.target);
        console.log("check DNS for " , this.domain, this.ip );
        axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
        axios.post('/api/checkDNS', { domain:this.domain , type:dns_type, extra: this.dns_dmarc, 
          selector: this.dmarc_selector, dmarc_key: this.dmarc_key, content: this.dmarc_txt,  xrefid: this.xrefid } ).then( response=>{
            console.log( "checkDNS OK, resp ", response);
            if (response.status == 200 ){
              
                if( response.data.code == 200 && response.data.message == 'OK'){
                   this.dmarc_alert.message = "设置成功";
                   this.dmarc_alert.type = 'success';
                }else{
                    this.dmarc_alert.message = "设置失败，请检查域名" + this.dmarc_selector + "._domainkey." + this.domain + "的 TXT 记录";
                    this.dmarc_alert.type = "warning";
                }
                this.dmarc_alert.show = true;
                this.dmarc_Status.isLoading = false;
                this.dmarc_Status.isDisabled = false;
            }
        }

        ).catch( (error)=>{
            console.log( "checkDNS ERROR", error );
            this.dmarc_Status.isLoading = false;
            this.dmarc_Status.isDisabled = false;
        });
        
    },
    syncDMarc:function(){
      let cdata = document.getElementById('idmarc_txt').value;
      console.log("MyCdata", cdata);
      let grps = cdata.match(/(\S+)\s+(\S+)\s+(\S+)\s+\(([^)]+)\)/);
      let dmarcRaw = grps[4];
      console.log("dmarcRaw", dmarcRaw);
      if ( typeof ( String.prototype.replaceAll3 ) == 'undefined'){
        console.log("add New replaceAll3")
        String.prototype.replaceAll3 = function (s1,s2){     
            return this.replace(new RegExp(s1,"gm"),s2);     
        } 

      }
      let sDns = dmarcRaw.replaceAll3(" ",'');
      sDns = sDns.replaceAll3("\n",'');
      sDns = sDns.replaceAll3("\"",'');
      sDns = sDns.replaceAll3(";",'; ');
      console.log("nd", sDns);
      this.dns_dmarc = sDns ;

    },
    loadServerIp:function(event)
    {
        this.resetStatus();
        if ( this.js_status_loading ) return ;
        if ( this.ip.length > 5)   return ;
        let o = event.target;
        o.setAttribute("placeholder","正在加载本机ip...");
        axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
        axios.get("/api/getHostIp").then( resp=>{
            console.log("loadMyIp ", resp );
            if (resp.status == 200 && resp.data.code == 200 ){
                this.ip = resp.data.data;
            }
            this.js_status_loading = false ;
            o.setAttribute("placeholder","");
            this.makeDns();
            
        } )
        .catch( error=>{
            console.log("error on loadServerIp", error);
            this.js_status_loading = false ;
            o.setAttribute("placeholder","");
        });
    },
    doPopuData:function(domain_response){
      console.log("doPopuData " , domain_response);
      if (domain_response.status == 200 && domain_response.data.code == 200)
      {
        this.loading_fail = false;
        let domainList = domain_response.data.data;
        if ( domainList.length ==0 ){
          return ;
        } 
        this.domain = domainList[0].domain;
        const mxO = JSON.parse( domainList[0].dns_mx );
        const mxname =  Object.keys(mxO)[0];
        this.dns_mx = mxname
        this.dns_a = mxname;
        this.ip = mxO[mxname];
        this.dmarc_txt = domainList[0].dmarc_txt;
        this.dmarc_key = domainList[0].dmarc_key;
        this.dns_dmarc = domainList[0].dns_dmarc;
        this.extrForm_rule_limit = domainList[0].rule_limit;
        this.extrForm_rule_pwd = domainList[0].rule_pwd;
        this.extrForm_appid = domainList[0].appid;
        this.extrForm_appsecret = domainList[0].appsecret;
        
      }else{
        this.loading_fail = true;
      }
    },
    getDKIM:function(){
      console.log("getDkim run for domian " + this.domain);
      if ( this.domain.length < 3 )
          return ;
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      let jsData = {"domain": this.domain };
      axios.post('/api/getDkimPT' , jsData)
     .then( response=>{
          console.log("response->" , response);
          if ( response.status == 200 && response.data.code == 200 )
          {
             let resp_data = response.data ;

             this.dmarc_txt = resp_data.data.text ;
             this.dmarc_key = resp_data.data.private;
             let that=this;
      
             that.$nextTick(function(){
                that.syncDMarc();
             });
             
          }
     } )
     .catch(error=>{
          console.log("error=>", error);

     })

    },
    randomStr:function( len )
    {
      len = len || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
      a = t.length,
      n = "";
      for (let i = 0; i < len; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n
    },
    genAppidSecret: function()
    {
      let appId = this.randomStr(8);
      let appSecret = this.randomStr(32);
      this.extrForm_appid = appId;
      this.extrForm_appsecret = appSecret;
    },
    saveExtra: function()
    {
      //TODO set post 
      let jsData = { domain:this.domain , appid: this.extrForm_appid, appsecret: this.extrForm_appsecret, 
         rule_limit: this.extrForm_rule_limit, rule_pwd: this.extrForm_rule_pwd, xrefid: this.xrefid }
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      axios.post('/api/updateDomainExtra', jsData )
        .then( response=>{
            console.log("saveExtra - resp =>", response);
            if( response.data.code == 200 && response.data.message == 'OK'){
                   this.extra_alert.message = "设置成功";
                   this.extra_alert.type = 'success';
                   if(  response.data.appid )
                     this.extrForm_appid = response.data.appid ;
                   if (response.data.appsecret)
                     this.extrForm_appsecret = response.data.appsecret ;
                   if (response.data.rule_pwd) 
                     this.extrForm_rule_pwd = response.data.rule_pwd ;
                   if ( response.data.rule_limit )
                     this.extrForm_rule_limit = response.data.rule_limit ;
                    //do set config 
                    this.genEConfig();
            }else{
                    this.extra_alert.message = "设置失败";
                    this.extra_alert.type = "warning";
            }
            this.dmarc_alert.show = true;
            
            
        } )
        .catch( error=>{
          console.log("saveExtra - error =>", error);
        })

    },
    genEConfig: function(){
      console.log("gen with pwd " , this.extrForm_rule_pwd);
      console.log("gen with appid " , this.extrForm_appid);
      console.log("gen with appsecret " , this.extrForm_appsecret);
    },
    genConfigText:function(){
      if ( this.extrForm_appid ){
        alert("genConfText");
        /*
[general]
url=http://mailmgr.idcpay.me/api/getNodeConf
appid=xxx
appsecret=xxxx
        */
        //console.log("win.url", window.location);
        let cUri = new URL( window.location.href) 
        let cfgFile = `[general]
url=${ cUri.origin }/api/getNodeConf
appid=${ this.extrForm_appid }
appsecret=${ this.extrForm_appsecret }` ;
        //console.log("CFG FILE", cfgFile);
        this.envConf = cfgFile;
      }

    }
    
  },

  async created(){
     console.log('The vue created.');
     axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
     await axios.get('/api/profile' ).then(
      response=>{
        console.log("profile ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            this.logininfo = response.data.data;
            const domain = this.$route.params.msg;
            if ( domain != 'new'){
              console.log("get this", domain );
              // 加载域名信息
              axios.get('/api/getDomainByName?domain=' + domain).then(
                 response=>{
                     this.doPopuData(response);
                     this.genEConfig();
                 }
              ).catch( err=>{
                  console.log("load domain error " +  domain, err )
              });

            }
            
        }else{
          window.location = '/login';
        }
      }
    )
    .catch(err=>{
      window.location = '/login';
      console.log(err);
    });
  }
};

</script>

