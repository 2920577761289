
<template>
  <v-container>
    <h3 style="padding-bottom: 20px;">管理你的域名</h3>
    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->
    <v-row>
      <v-col cols="1">
        <v-btn block @click="addNewDomain($event)" color="primary">新增域名</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="查找"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="domains"
            :search="search"
          >
            <template v-slot:[`item.doEdit`]="{ item }">
            <v-chip
             color="primary"
               dark
               @click="doEdit({item})"
            >
              修改 {{ item.none }}
            </v-chip>
          </template>
          <template v-slot:[`item.doDel`]="{ item }">
            <v-chip
             color="secondary"
               dark
               @click="doDel({item})"
            >
              删除 {{ item.none }}
            </v-chip>
          </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
 
<script>
import axios from 'axios';
export default {

  name: "vTyWorkSpace",

  data: () => ({
    logininfo: {},
    search : '',
    headers:[
    { text: '域名', value: 'domain' },
    { text: 'mx', value: 'mx' },
    { text: 'spf', value: 'spf' },
    { text: 'dmarc', value: 'dmarc' },
    { text: 'ptr', value: 'ptr' },
    { text: 'uid', value: 'uid' },
    { text: '收件数量', value: 'mailcount' },
    { text: '编辑', value: 'doEdit' },
    { text: '删除', value: 'doDel' },
    ],

    domains:[
      {domain:"loading 正在加载"},

    ],
  
  }),
  methods:{
    addNewDomain(event){
        console.log(event);
        this.$router.push({ name: "vPageEditDomain", params: { msg: 'new' } });
    },
    editDomain(data){
        console.log(data);
        console.log("go edit domain", data.item.domain )
        this.$router.push({ name: "vPageEditDomain", params: { msg: data.item.domain } });
    },
    _helpMxStr(mx){
        console.log(mx);
        mx = JSON.parse(mx)
        let ss = '';
        for( let k in mx){
            ss = ss + k ;
            ss = ss + ":" + mx[k];
        }
        return ss;
    }
    ,
 
    doEdit:function(item ){
        console.log( 'doEdit', item );
        this.editDomain(item);
    },
    doDel:function( item ){
        console.log( 'doDelete domain:', item );
        //this.editDomain(item);
        this.$confirm(
           ()=>{
            console.log(this);
            console.log("I wan to del ", item )
           }
         ).then( ( )=>{  
          
          console.log( "op ", item );
          this.trueDelDomain(item.item.domain);
         } 
          
         ).catch(
            (err)=>{
              console.log("err", err);
            }
          ); 
    },
    trueDelDomain:function( domain ){
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      axios.delete( '/api/delDomain?domain='+domain ).then(

        resp=>{
          if (resp.status==200){
            this.loadDomains();
          }else{
            console.log("meet excpetion not 200 ");
          }
        }

      ).catch(
        err=>{
          console.log("meet excpetion ", err);
        }
      );
    },
    loadDomains: function(){
    let alt = 2;
    if (alt==1) return;
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    axios.get('/api/getDomains' ).then( response=>{ 
      console.log("/getDomains", response);
      this.domains = [];
      if ( response.status == 200 && response.data.code == 200){
        let mylist = response.data.data;
        for ( let i in mylist){
          console.log( "item", mylist[i] );
          let citem = {};
          citem.domain = mylist[i].domain;
          citem.mailcount = mylist[i].recv_count;
          citem.mx = this._helpMxStr(mylist[i].dns_mx)
          
          this.domains.push( citem );
        }
      }
      //for(const i in rep)
      
    }).catch(err=>{
      console.log(err);
    });
    }
 
  },
  mounted: function(){
     this.loadDomains()
  },
  async created(){
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    await axios.get('/api/profile' ).then(
      response=>{
        console.log("profile ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            this.logininfo = response.data.data;
        }else{
          window.location = '/login';
        }
      }
    )
    .catch(err=>{
      window.location = '/login';
      console.log(err);
    });
  }
};

</script>

