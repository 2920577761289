
<template>
  <v-container>
    <v-card dense>
      <v-row > 
        <v-col style="padding: 0px; padding-left: 18px; padding-top: 4px;"><div style="align:center;cursor:pointer;padding:2px" @click="this.toMailList"><v-icon>mdi-account</v-icon>关闭</div></v-col>
      </v-row>
      <v-row>
        <v-col style="padding: 8px" clos="12"><v-card-text style="padding: 4px; padding-left: 12px;font-weight: bold;">{{this.subject}}</v-card-text></v-col>
      </v-row>
      <v-row>
        <v-col style="padding: 8px" clos="6"><v-card-text style="padding: 4px; padding-left: 12px;" ><strong>{{this.from_name}}</strong> &lt;{{this.from_addr}}&gt;</v-card-text> <v-card-text style="padding: 4px; padding-left: 12px;">发送到 {{this.to_addr}}</v-card-text> </v-col>
        <v-col style="padding: 8px; padding-right: 20px;" clos="6"><v-card-text  style="padding: 4px; text-align: right;" >{{this.mail_time}}</v-card-text></v-col>
      </v-row>
      <v-row>
        <v-col style="padding: 8px" clos="12">
          <div style="padding: 8px" v-html="body">  </div>
        </v-col>
      </v-row>
      
      
      <div v-show="has_attach">
        <v-card-text>附件列表</v-card-text>
        
        <div style="padding: 8px; padding-left: 20px; padding-right: 20px;" clos="6" v-for="(data, index) in this.attach_list " :key="index">
           <a :href="data.url">{{ data.filename }}</a>
        </div>
      </div>
      <v-card-text> id: {{this.ref_id}}</v-card-text>
      <v-card-text @click="this.toMailList" style="cursor: pointer"> 回退 </v-card-text>
    </v-card>
  </v-container>
</template>
 
<script>
import axios from 'axios';
export default {

  name: "vMailContent",

  data: () => ({
    logininfo:{},
    from_name : '',
    from_addr :'',
    to_addr :'',
    subject :'',
    mail_time :'',
    ref_id : '',
    body : '',
    has_attach: false,
    attach_list : []
  
  }),
  methods:{
    toMailList: function(){
      this.$router.push({ name: "vEmails", params: { msg: "emails" } });
      //this.$router.go(-1);
    }
  },
  mounted: function(){
    //alert("OK");
    let alt = 2;
    if (alt==1) return;
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    axios.get('/api/getmail?mailid='+this.$route.params.mailid ).then( response=>{ 
      console.log("/getmail", response);
      let ajData = response.data.data;
      this.subject = ajData.subject;
      this.from_addr = ajData.from_addr;
      this.to_addr = ajData.to_addr;
      this.mail_time = ajData.mail_time;
      this.body = ajData.body;
      this.from_name = ajData.from_name;
      this.ref_id = ajData.ref_id;
      this.attach_list = ajData.msg_att;
      if ( this.attach_list && this.attach_list.length > 0)
      {   
        this.has_attach = true ;
        for(let item of this.attach_list){
          item.url = '/api/getmailatt?mailid='+this.ref_id+"&attid="+item.fid;
        }
      }
      //for(const i in rep)
      
    }).catch(err=>{
      console.log(err);
    });
  }
  ,
  async created(){
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    await axios.get('/api/profile' ).then(
      response=>{
        console.log("profile ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            this.logininfo = response.data.data;
        }else{
          window.location = '/login';
        }
      }
    )
    .catch(err=>{
      window.location = '/login';
      console.log(err);
    });
  }
};

</script>

