import Vue from 'vue'
import vConfirm from '@/components/vConfirm.vue'
const VueComponent = Vue.extend(vConfirm)
const vm = new VueComponent().$mount()
 
let init = false;
let defaultOptions = {
	
}
 
const confirm = function(options){
	Object.assign(vm,defaultOptions,options,{
		type:"confirm"
	})
	if(!init){
		document.body.appendChild(vm.$el)
		init = true;
	}
	return vm.confirm();
};
 
export default confirm;
