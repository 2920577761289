
<template>
  <v-container>
    <h3 style="padding-bottom: 20px;">邮件列表</h3>
    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->
    
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="查找"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="maillist"
            :search="search"
            :loading="loading"
            :server-items-length="totalCnt"
            :options.sync="options"
            :footer-props="{
                     itemsPerPageOptions: [  50, 100] ,
                     'items-per-page-text': '每页展示'} "
          >
            <template v-slot:[`item.from_addr`]="{ item }">
              <div :style="{ 'font-weight' : getFontWeight(item) }"
              >
               {{item.from_addr}}
              </div>
            </template>
            <template v-slot:[`item.to_addr`]="{ item }">
              <div :style="{ 'font-weight' : getFontWeight(item) }"
              >
               {{item.to_addr}}
              </div>
            </template>
            <template v-slot:[`item.subject`]="{ item }">
              <div :style="{'padding':'3px', 'cursor': 'pointer','font-weight' : getFontWeight(item) }" @click="subjectClick(item)"
              >
                  {{ item.subject }} 
              </div>
            </template>
            <template v-slot:[`item.mail_time`]="{ item }">
              <div :style="{  'font-weight' : getFontWeight(item) }" @click="subjectClick(item)"
              >
                  {{ item.mail_time }} 
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
 
<script>
import axios from 'axios';
export default {

  name: "vSendLogs",

  data: () => ({
    logininfo : {},
    search : '',
    headers:[
    { text: '发件人', value: 'from_addr' , width: 18 },
    { text: '收件人', value: 'to_addr' , width: 120},
    { text: '内容', value: 'subject' },
    { text: '时间', value: 'mail_time' , width: 120 },
    { text: '状态码', value: 'respond_code' , width: 120 },
    
    ],

    maillist:[
      
    ],
    loading : true,
    //for options
    options: { itemsPerPage:50, page:1},
    totalCnt : 0,
  
  }),
  methods:{
    subjectClick: function(item){
      console.log( "click item", item );
      console.log( "open mail " + item.ref_id );
      this.$router.push( {name : "vEmailContent", params:{mailid: item.ref_id } } )
      //#this.$router.push({ name: "vSetting", params: { msg: path } });
    },
    getFontWeight(item)
    {
      console.log( "item.is_read ", item.is_read, item.subject )
      return (item.is_read == 1) ? "normal":"bold";
    },
    loadData: async function(){
      this.loading = true;
      let pageId = this.options.page  ;
      let pageNum = this.options.itemsPerPage; 

      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      axios.get('/api/sendinglogs?page_index='+pageId+"&page_size="+pageNum ).then( response=>{ 
      console.log("/sendinglogs", response);
      this.maillist = [];
      if ( response.status == 200 && response.data.code == 200){
        let mylist = response.data.data;
        this.loading = false;
        for ( let i in mylist){
          //console.log( "item", mylist[i] );
          let citem = {};
          citem.from_addr = mylist[i].from_addr;
          citem.to_addr = mylist[i].to_addr;
          citem.mail_time = mylist[i].mail_time;
          citem.subject = mylist[i].subject;
          citem.respond_code = mylist[i].respond_code;
          citem.respond_msg = mylist[i].respond_msg;
          this.maillist.push( citem );
        }
        this.totalCnt = response.data.total;
      }
      //for(const i in rep)
      
    }).catch(err=>{
      console.log(err);
    });
    
    }
  },
  created: async function(){
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    await axios.get('/api/profile' ).then(
      response=>{
        console.log("profile ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            this.logininfo = response.data.data;
        }else{
          window.location = '/login';
        }
      }
    )
    .catch(err=>{
      window.location = '/login';
      console.log(err);
    });

    let alt = 2;
    if (alt==1) return;
    //TODO load data
    await this.loadData();
  },

  watch: {
      options: {
        handler () {
          console.log("options", this.options);
          console.log("Go to page " , this.options.page );
          console.log("Go to page size " , this.options.itemsPerPage );
          this.loadData();
        },
        deep: true,
      },
  }
};

</script>

