<template>
  <v-app id="inspire">
    <v-card>
      <v-navigation-drawer v-model="drawer" app>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> 推邮系统 </v-list-item-title>
            <v-list-item-subtitle>
              {{ tySysOwner }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item v-for="item in items" :key="item.title" link>
            <v-list-item-icon>
              <v-icon :color="item.color">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-on:click="jmpPage(item.kk)">
                {{ item.title }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!--  -->
      </v-navigation-drawer>
    </v-card>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ tySysOwner }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <!--  -->
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
  
<script>
import axios from 'axios';

export default {
  data: () => ({
    drawer: null,
    items: [],
    tySysOwner: "System admin",
    logininfo : {}
  }),

  created: async function () {
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
    await axios.get('/api/profile' ).then(
      response=>{
        console.log("profile ", response);
        if (response.status==200 && response.data.code==200){
            console.log("ok");
            this.logininfo = response.data.data;
        }else{
          window.location = '/login';
        }
      }
    )
    .catch(err=>{
      window.location = '/login';
      console.log(err);
    });

    this.tySysOwner;
    this.items = [
      { title: "域名", icon: "mdi-domain", color: "blue darken-2", kk: "domains" },
      { title: "邮件列表", icon: "mdi-email", color: "green darken-2", kk: "emails" },
      { title: "发送记录", icon: "mdi-notebook", color: "green darken-2", kk: "sendlogs" },
      { title: "设置帮助", icon: "mdi-asterisk", color: "orange darken-2", kk: "setting" },
    ];
  },

  methods: {
    jmpPage: function (path) {
      if ( path == "domains")
         this.$router.push({ name: "vDomains", params: { msg: path } });
      else if ( path == "emails")
         this.$router.push({ name: "vEmails", params: { msg: path } });
      else if ( path == 'sendlogs')
      
         this.$router.push({ name: "vSendLogs", params: { msg: path } });
      else if ( path == "setting")
         this.$router.push({ name: "vSetting", params: { msg: path } });
    },
  },
};
</script>