import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import confirm from '@/plugins/vconfirm'

import VueRouter from 'vue-router'

import router from './router'

Vue.use(VueRouter) //启用vue-router插件
Vue.config.productionTip = false


Vue.prototype.$confirm = confirm

console.log( "I use VUE_APP_BASEURL sx ", process.env );
//console.log( "Vue.config", Vue.config);
new Vue({
  vuetify,
  router:router,
  
  render: h => h(App)
}).$mount('#app')
